import React from 'react';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import Category from '../category/Category';
import "./Contacts.css"

const techStyle = { width: 70, height: 70, margin: 5, marginBottom: 30, marginTop: 30, marginLeft: 50, marginRight: 50 }

const Contacts: React.FC = () => {

  const handleGithubClick = () => {
    window.open("https://github.com/stianmogen", "_blank");
  }

  const handleEmailClick = () => {
    window.open("mailto:stfjae@gmail.com");
  }

  const handleLinkedInClick = () => {
    window.open("https://linkedin.com/in/stianmogen", "_blank");
  }

  return (
    <>
      <Category title={"Get in touch"} id={"contact"} />
      <div className={"contacts"}>
        <FaGithub style={techStyle} onClick={handleGithubClick} />
        <FaEnvelope style={techStyle} onClick={handleEmailClick} />
        <FaLinkedin style={techStyle} onClick={handleLinkedInClick} />
      </div>
    </>
  );
};

export default Contacts;
