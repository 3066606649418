import React from 'react';
import './Technologies.css';
import java from '../../assets/images/technologies/java-logo.webp'
import python from '../../assets/images/technologies/python-logo.webp'
import pythorch from '../../assets/images/technologies/pytorch-logo.png'
import react from '../../assets/images/technologies/react-logo.webp'
import typescript from '../../assets/images/technologies/typescript-logo.webp'

const techStyle = {
  width: window.innerWidth < 600 ? 50 : 70,
  height: window.innerWidth < 600 ? 50 : 70,
  margin: 5,
  marginBottom: 50
};
const Technologies: React.FC = () => {
  return (
    <div style={{ display: 'flex' }}>

      <img
        src={java}
        alt="Java logo"
        className="tech-style"
      />
      <img
        src={python}
        alt="Python logo"
        className="tech-style"
      />
      <img
        src={pythorch}
        alt="PyTorch logo"
        className="tech-style"
      />
      <img
        src={react}
        alt="React logo"
        className="tech-style"
      />
      <img
        src={typescript}
        alt="TypeScript logo"
        className="tech-style"
      />
    </div>
  );
};

export default Technologies;
