import React from 'react';
import "./Category.css"

type Props = {
  title: string;
  id: string;
}

const Category: React.FC<Props> = ({ title, id }) => {
  return (
    <h2 className={"category"} id={id}>{title}</h2>
    )
}

export default Category;
