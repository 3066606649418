import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

interface Props {
  scrollToSection: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

function Navbar(props: Props) {

  return (
    <nav className="navbar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a href="#home" className="nav-link" onClick={props.scrollToSection}>
            Home
          </a>
        </li>
        <li className="nav-item">
          <a href="#projects" className="nav-link" onClick={props.scrollToSection}>
            Projects
          </a>
        </li>
        <li className="nav-item">
          <a href="#education" className="nav-link" onClick={props.scrollToSection}>
            Education
          </a>
        </li>
        <li className="nav-item">
          <a href="#work" className="nav-link" onClick={props.scrollToSection}>
            Work
          </a>
        </li>
        <li className="nav-item">
          <a href="#contact" className="nav-link" onClick={props.scrollToSection}>
            Contact
          </a>
        </li>
        <li className="nav-item">
          <Link to="/julebord" className="nav-link">
            Julebord
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
