import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

interface Props {
  scrollToSection: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

function NavbarCV(props: Props) {

  return (
    <nav className="navbar">
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to=".." className="nav-link">
            Home
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default NavbarCV;
