import React from 'react';
import './../../App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Card from '../card/Card';
import './Julebord.css'
import cruse from '../../assets/images/winelist/cruse.png'
import weing from '../../assets/images/winelist/weing.png'
import palarsen from '../../assets/images/winelist/palarsen.png'
import villawolf from '../../assets/images/winelist/villawolf.png'
import wente from '../../assets/images/winelist/wente.png'


function Julebord() {
  const scrollToSection = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const section = event.currentTarget.getAttribute("href");
    if (section) {
      const sectionElement = document.querySelector(section);
      if (sectionElement) {
        sectionElement.scrollIntoView({behavior: "smooth"});
      }
    }
  }
  return (
    <>
    <header className="App-content">
    <div className="App-header" id="julebord">
    <h1>Vinliste: Julebord 23
    <p>
    Et utvalg håndplukkede	&#127863; av sommelier Mogen &#127864; (Sorry Simon ble ikke noe skole i dag)
    </p></h1>
    </div>
    <div className='card-wrapper'>
    <div className="card-container">
        <Card 
            image={wente}
            alt="wente"
            title="Wente Morning Fog Chardonnay"
            description={
                <>
                Chardonnay er en sikker vinner for deg som liker hvitvin av det tørre slaget. Vi begynner kvelden med denne tilsynelatende nøkterne vinen, men ikke la deg lure! En hvitvin med 13.5% alkohol setter standarden for resten av kvelden.
                <ul>
                    <li>År: 2021</li>
                    <li>Drue: Chardonnay</li>
                    <li>Region: Livermore Valley, California,</li>
                    <li>Produsent: Wente Est.</li>
                    <li>Pris: 199,90</li>
                    <li>Alkohol: 13.5%</li>
                    <li>DN: 82</li>
                    <li>Vivino: 3.9</li>
                </ul>
                </>
            }
            link={["https://www.vinmonopolet.no/Land/USA/California/Livermore-Valley/Wente-Morning-Fog-Chardonnay-2021/p/796301", "Polet"]}
            secondaryLink={["https://www.dn.no/smak/vin/morning-fog-chardonnay/4-1-165085", "DN"]}
        />
        <Card 
            image={villawolf}
            alt="villawolf"
            title="Villa Wolf Pinot Noir 2022"
            description={
                <>
                Pinot Noir er en fantastisk vin til mat. Både til rødt kjøtt, men også kylling og fisk. Pinot Noir brukes i mange av de mest annerkjente vinene i verden. Tradisjonelt er Pinot Noir fra Frankrike og USA svært dyre å få tak i av høy kvalitet. Vi tar derfor turen til Tyskland, for det som sies å være blandt polets aller beste kjøp. 
                <ul>
                    <li>År: 2022</li>
                    <li>Drue: Pinot Noir</li>
                    <li>Region: Pfalz, Tyskland</li>
                    <li>Produsent: Weing, Villa Wolf</li>
                    <li>Pris: 167,50,</li>
                    <li>Alkohol: 12.5%</li>
                    <li>DN: 85</li>
                    <li>Vivino: 3.7 </li>
                </ul>
                </>
            }
            link={["https://www.vinmonopolet.no/Land/Tyskland/Pfalz/Villa-Wolf-Pinot-Noir-2022/p/2147101", "Polet"]}
            secondaryLink={["https://www.dn.no/smak/vin/villa-wolf-pinot-noir/4-1-133957", "DN"]}
        />   
        <Card 
            image={palarsen}
            alt="palarsen"
            title="P. A. Larsen Claret 2022"
            description={
                <>
                Bordeaux trenger ingen introduksjon, men en drikkeklar Bordeaux til 200kr er intet mindre enn et unikum. Et av de beste kjøpene på årets novemberslipp. En høykvalitets Bordeaux som kan drikkes nå, men også lagres og åpnes om 10 år. Vi er ikke like tålmodige. Begynn med en slurk og kjenn på den kompakte smakes. La den derette hvile litt, spinn glasset rundt, og legg merke til hvordan smakene åpner seg. 
                <ul>
                    <li>År: 2022</li>
                    <li>Drue: Cabernet Sauvignon 60% Merlot 40%</li>
                    <li>Region: Bordeaux, Frankrike,</li>
                    <li>Produsent: P.A. Larsen</li>
                    <li>Pris: 209,90,</li>
                    <li>Alkohol: 13.5%</li>
                    <li>DN: 88</li>
                    <li>Vivino: 3.9</li>
                </ul>
                </>
            }
            link={["https://www.vinmonopolet.no/Land/Frankrike/Bordeaux/P-A-Larsen-Claret-2022/p/16477801", "Polet"]}
            secondaryLink={["https://www.dn.no/smak/vin/palarsen-claret/4-1-209895", "DN"]}
        />     
        <Card 
        image={cruse}
        alt="cruse"
        title="Cruse Rancho Chimiles Valdiguié 2019"
        description={
            <>
            Kveldens høydepunkt. Denne vinen ble kjøpt av undertegnede på polet sitt marsslipp i 2022, da den ble varmt anbefalt som en av de tre beste rødvinskjøpene fra USA den våren. Den har godgjort seg i temperert vinskap i snart et år. Denne ekslusive vinen fra Nappa Valley er utenfor vinmonopolet sitt sortiment, og dermed umulig å få kjøpt lenger. Det har den jo forøvrig vært siden mars 2022. 
            <ul>
                <li>År: 2019</li>
                <li>Drue: Valdiguié</li>
                <li>Region: Napa Valley, California</li>
                <li>Produsent: Cruse Wine Co. , California , USA</li>
                <li>Pris: 264,80</li>
                <li>Alkohol: 13%</li>
                <li>DN: 90</li>
                <li>Vivino: 4.1</li>
            </ul>
            </>
        }
        link={["https://www.vinmonopolet.no/Land/USA/California/Napa-Valley/Cruse-Rancho-Chimiles-Valdigui%C3%A9-2019/p/10065101", "Polet"]}
        secondaryLink={["https://www.dn.no/smak/vin/cruse-rancho-chimiles-valdiguie/4-1-140772", "DN"]}
        />
        <Card 
            image={weing}
            alt="weing"
            title="Weing. Hahnmühle Riesling - Roter Traminer 2022"
            description={
                <>
                Vi avslutter med noe litt lettere. Blandt de beste kjøpene på årets Novemberslipp i følge DN. Riesling er en matvin, som kan kombineres med nesten alt, inkludert julemat. 
                <ul>
                    <li>År: 2022</li>
                    <li>Drue: Riesling</li>
                    <li>Region: Nahe, Tyskland</li>
                    <li>Produsent: Weing. Hahnmühle</li>
                    <li>Pris: 229,10</li>
                    <li>Alkohol: 12.5%</li>
                    <li>DN: 87</li>
                    <li>Vivino: 4.1</li>
                </ul>
                </>
            }
            link={["https://www.vinmonopolet.no/Land/Tyskland/Nahe/Weing-Hahnm%C3%BChle-Alsenzer-Mischsatz-Tradition-Riesling-%2B-Roter-TraminerTrocken-2022/p/15913901", "Polet"]}
            secondaryLink={["https://www.dn.no/smak/vin/weing-hahnmuhle-alsenzer-mischsatz-tradition-riesling-roter-traminertrocken/4-1-203051", "DN"]}
        />

    </div>
    </div>
    </header>
    </>
  );
}

export default Julebord;
