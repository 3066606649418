import React from 'react';
import '../../App.css'
import Card from '../card/Card';
import WorkTechnologies from '../technologies/WorkTechnologies';
import Timeline from '../timeline/Timeline';
import hoopla from '../../assets/images/projects/hoopla.avif'

const events = [
    { title: "☀️ 2023 Bekk", date: 'Summer 2023', content: 'Data analyst @ Bekk.' },
    { title: "☀️ 2022 Hoopla", date: 'Summer 2022', content: 'Summer Intern @ Hoopla.' },
    { title: "💻 Software Developer", date: 'Fall 2021 - Presemt', content: 'Full-stack developer @ Hoopla.' },
    { title: "📞 Customer Advisor", date: 'Winter 2020 - Fall 2021', content: 'Customer Advisor @ Hoopla.' },
    { title: "👷 Teaching Assistant", date: 'Spring 2021, 2023', content: 'Teaching assistant in the subject Experts in Teamwork.' },
    { title: "🤾 Athlete", date: '2018-2019', content: 'Professional handball player for Elverum Håndball.' },
    { title: "🏫 Student Assistant", date: 'Fall 2018 - Spring 2019', content: 'Working with specialized education for single student, and substitute teacher in different subjects.' },
  ];

  

function Work() {
  return (
    <>
    <h2 id={"work"}> Work experience </h2>
    <WorkTechnologies/>
    <div className="card-container">
        <Card 
        image={hoopla}
        alt="hoopla"
        title="Hoopla.no"
        description="Junior Developer at the event ticket system Hoopla. Having worked part time as a developer since the autumn of 2021."
        link={["https://hoopla.no", "Hoopla.no"]}
        />
    </div>

    <Timeline events={events}/>
    </>
  );
}

export default Work;