import React from 'react';
import './Card.css';

interface CardProps {
    image: string;
    alt: string;
    title: string;
    description: React.ReactNode;
    link: string[];
    secondaryLink?: string[]; //optional
  }

function Card(props: CardProps) {
  return (
    <div className="card">
      {props.image ? <img src={props.image} alt={props.alt} /> : null}
      <div className="card-content">
        <h2>{props.title}</h2>
        <div className="paragraph-style">{props.description}</div>
        <div className="card-links">
          <a href={props.link[0]} target="_blank" rel="noreferrer"><p>{props.link[1]}</p></a>
          {props.secondaryLink && <a href={props.secondaryLink[0]} target="_blank" rel="noreferrer"><p>{props.secondaryLink[1]}</p></a>}
        </div>
      </div>
    </div>
  );
}

export default Card;
