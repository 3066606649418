import React from 'react';
import scala from '../../assets/images/technologies/scala-logo.png'
import react from '../../assets/images/technologies/react-logo.webp'
import typescript from '../../assets/images/technologies/typescript-logo.webp'
import javascript from '../../assets/images/technologies/javascript-logo.webp'
import postgresql from '../../assets/images/technologies/postgresql-logo.png'
import elixir from '../../assets/images/technologies/elixir-logo.png'


const workTechStyle = {
    width: window.innerWidth < 600 ? 40 : 50,
    height: window.innerWidth < 600 ? 40 : 50,
    margin: 5,
    marginBottom: 50
  };

const WorkTechnologies: React.FC = () => {
    return (
        <div style={{ display: 'flex' }}>
            <img
                src={scala}
                alt="Scala logo"
                className="work-tech-style"
            />
            <img
                src={react}
                alt="React logo"
                className="work-tech-style"
            />
            <img
                src={typescript}
                alt="TypeScript logo"
                className="work-tech-style"
            />
            <img
                src={javascript}
                alt="JavaScript logo"
                className="work-tech-style"
            />
                <img
                src={postgresql}
                alt="PostgreSQL logo"
                className="work-tech-style"
            />
            <img
                src={elixir}
                alt="Elixir logo"
                className="work-tech-style"
            />
        </div>
    );
};

export default WorkTechnologies;



