import React from 'react';
import '../../App.css'
import Card from '../card/Card';
import eyetracking from '../../assets/images/projects/eyetracking.png'
import opencv from '../../assets/images/projects/opencv.png'
import languagedetection from '../../assets/images/projects/languagedetection.png'
import gidd from '../../assets/images/projects//gidd.jpeg'
import Technologies from '../technologies/Technologies';
import Timeline from '../timeline/Timeline';
import Category from '../category/Category';

const events = [
  { title: "Masters Degree", date: 'Fall 2022 - Present', content: 'M.Sc Artificial Intelligence @ NTNU.' },
  { title: "Bachelors Degree", date: 'Fall 2019 - Spring 2022', content: 'B.Sc System Developer @ NTNU.' },
];

function Projects() {
  return (
    <>
    <Category title={"Projects"} id={"projects"}/>
    <Technologies/>
    <div className="card-container">
        <Card 
        image={eyetracking}
        alt="eyetracking"
        title="Bachelor Thesis"
        description="Application of machine learning on eyetracking data from VR-environment"
        link={["https://ntnuopen.ntnu.no/ntnu-xmlui/handle/11250/3006197", "Thesis"]}
        />
        <Card 
        image={opencv}
        alt="app"
        title="Android OpenCV"
        description="Image classification using OpenCV on Android device"
        link={["https://github.com/stianmogen/IDATT2501-road-sign-detection", "Source code"]}
        secondaryLink={["https://github.com/stianmogen/IDATT2501-road-sign-detection/blob/main/IDATT2501-rapport.pdf", "Report"]}
        />
        <Card 
        image={languagedetection}
        alt="language"
        title="Language Detection"
        description="Using RNN and Naive Bayesian classifier for identifying languages"
        link={["https://github.com/stianmogen/IDATT2502-language-detection", "Source code"]}
        secondaryLink={["https://github.com/stianmogen/IDATT2502-language-detection/blob/main/docs/Anvendelse%20av%20Maskinl%C3%A6ringsmodeller%20for%20Identifikasjon%20av%20Spr%C3%A5k.pdf", "Report"]}
        />
        <Card 
        image={gidd}
        alt="gidd"
        title="Gidd"
        description="Website designed for sharing activites, developed using the Scrum metholodgies in a team of eight members."
        link={["https://github.com/stianmogen/GIDD", "Source code"]}
        />
    </div>
    <h2 id={"education"}> Education </h2>
    <Timeline events={events}/>
    </>
  );
}

export default Projects;