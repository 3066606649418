import React from 'react';
import './Timeline.css';

interface TimelineEvent {
  title: string;
  date: string;
  content: string;
}

interface TimelineProps {
  events: TimelineEvent[];
}

const Timeline = ({ events }: TimelineProps) => {
  return (
    <div className="timeline">
      {events.map((event, index) => (
        <div className="timeline-event" key={index}>
          <div className="timeline-event-date">{event.date}</div>
          <div className="timeline-event-content">
            <h3>{event.title}</h3>
            <p>{event.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
