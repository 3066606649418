import React from 'react';
import './App.css';
import Projects from './components/projects/Projects';
import Navbar from './components/navnbar/Navbar';
import Work from './components/work/Work';
import Contacts from './components/Contacts/Contacts';
import Comment from './components/comment/Comment';
import CV from './components/cv/Cv';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavbarCV from './components/navnbar/NavbarCV';
import Julebord from './components/julebord23/Julebord';


function App() {
  const scrollToSection = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const section = event.currentTarget.getAttribute("href");
    if (section) {
      const sectionElement = document.querySelector(section);
      if (sectionElement) {
        sectionElement.scrollIntoView({behavior: "smooth"});
      }
    }
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={
          <div className="App">
            <Navbar scrollToSection={scrollToSection}/>
            <header className="App-content">
              <div className="App-header" id="home">
                <h1> Stian Fjæran Mogen <p>
                Software developer &#x1F4BB; M.Sc AI &#129302;
              </p></h1>
                
              </div>
                <Comment/>
                <Projects/>
                <Work/>
                <Contacts/>
            </header>
          </div>
          } />
        <Route path="/cv" element={
          <div className="App">
          <NavbarCV scrollToSection={scrollToSection}/>
        <CV/>
        </div>
        }/>
        <Route path="/julebord" element={
          <div className="App">
          <NavbarCV scrollToSection={scrollToSection}/>
        <Julebord/>
        </div>
        }/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
