import React, { useState, useEffect } from 'react';
import "./Comment.css"

const comments = [
  'animations',
  'media queries',
  'better timeline',
  'feedback retrieval',
  'not procrastinating',
  'language support',
  'light mode',
  'cv download'
];

const Comment: React.FC = () => {
  const [comment, setComment] = useState(comments[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * comments.length);
      setComment(comments[randomIndex]);
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);


  return (
    <div className="comment">
      Portfolio is WIP, currently working on{" "}
      <span className="comment-text" style={{ fontWeight: "bold" }}>{comment}</span>
    </div>
  );
};

export default Comment;
